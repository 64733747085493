<template>
    <div style="margin-top: 30px; padding: 0 20px;">
        <div class="title">Alternative Method of Promotion Entry</div>
        <div class="content"><b>How to enter a promotion for free (that is, without buying anything).</b></div>
        <div class="content"><b>There are two ways to participate for free in the Game Wonder Promotion:</b> </div>
        <div class="title1">
            1. By playing the no-prize play money game.
        </div>
        <div class="content">
            1.1. You can play no-prize play money game, using the thirty thousand (30 000) coins for participation in the no-prize play-money  game that your received on the day that you connected to the Game Wonder  Website.
        </div>
        <div class="title1">
            2. By requesting free entries by mail.
        </div>
        <div class="content">
            2.1. Free entry request by United States Postal Service first class  mail can be accessed as follows. For each complying request by United  States Postal Service first class mail received by the Sponsor, the  Sponsor will award  free (SC 1.00) entries.
        </div>
        <div class="content">
            2.2. To request by mail: 
        </div>
        <div class="content">
            2.2.1. On a white piece of paper no smaller than three inches by five inches, legibly write by hand:
        </div>
        <ul>
            <li>(a) "Game Wonder Free Entry Request,"</li>
            <li>(b) your full legal name,</li>
            <li>(c) your date of birth,</li>
            <li>(d) the full street address of where you live,</li>
            <li>(e) your telephone number,</li>
            <li>(f) your email address,</li>
            <li>(g) your Game Wonder user account number.</li>
        </ul>
        <div class="content">
            Then write by hand on an envelope:
        </div>
        <ul>
            <li>(a) your return address,</li>
            <li>(b) address by hand the envelope to 821 N ST STE 102 Anchorage AK 99501. </li>
        </ul>
        <div class="content">
            Use the envelope that you hand-addressed to send your request by U.S.  Postal Service mail per the address set out above. Only one request may  be mailed in an envelope.
        </div>
        <div class="content">
            2.3. Limit for individual requests by mail: 100 entries per complying  stamped outer envelope containing a complying written request.
        </div>
        <div class="content">
            2.4. A Free Entry Request can be accepted no more than once a day for one Game Wonder account.
        </div>
        <div class="content">
            2.5. The sponsor will process each complying request by accurating  free Coins to participant's Game Wonder account balance and by sending  an email confirmation regarding the procedure outcome.
        </div>
        <div class="content">
            2.6. Free entry requests will be disqualified for any of:
        </div>
        <ul>
            <li>(1) illegibility,</li>
            <li>(2) inclusion one envelope of more than a single free entry request  or inclusion with the free entry request of any other correspondence or  other materials,</li>
            <li>(3) lost, late, damaged, misdirected or postage due requests,</li>
            <li>(4) requests that in the sole opinion of the Sponsor are machine  generated, in whole or in part, including (not limited to) postage  affixed by postage meter or other device and further including the outer  envelope,</li>
            <li>(5) requests received from any source other than United States Postal Service first class mail.</li>
        </ul>
        <div class="content">
            2.7. The decision regarding eligibility or disqualification of free  entry requests shall be solely that of the Sponsor and shall be final.  Free entry requests received by the Sponsor become the Sponsor's  property upon receipt. Ineligible and non-complying free entry requests  will not be acknowledged. Free entries have no cash value: they can not  be used to purchase goods or services from any source or transferred  from one person to another.
        </div>
    </div>
</template>
<script>
export default {
    name:"PromotionEntry",
    title: "PromotionEntryView",
    methods: {
        onClickHF: function(){
            this.$router.push("/")
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    font-size: 18px;
}
.title {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 23px
}

.title1 {
    display: block;
    text-align: left;
    font-weight: bold;
}

.version {
    display: block;
    text-align: left;
}

.content {
    display: block;
    text-align: left;
}

div {
    margin: 12px;
}
</style>