<template>
    <div style="margin-top: 30px; padding: 0 20px;">
        <div class="title">Terms of Use</div>
        <div class="version">Version 1.0</div>
        <div class="content"><b>IMPORTANT NOTICE: These Terms of Use have been updated as of 17 November, 2023, and supersede and replace all prior Terms & Conditions.</b></div>
        <div class="content"><b>NOTE: THIS APPLICATION,WEBSITE AND THE SERVICES PROVIDED HEREIN DO NOT OFFER "REAL MONEY GAMBLING".</b> No actual money is required to play, and the Service is intended for entertainment purposes only.</div>
        <div class="content"><b>PLEASE NOTE THAT THESE TERMS OF USE INCLUDE A MANDATORY ARBITRATION PROVISION WHICH REQUIRES THAT ANY PAST, PENDING, OR FUTURE DISPUTES BETWEEN YOU AND US SHALL BE RESOLVED BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL AND NOT A CLASS-WIDE OR CONSOLIDATED BASIS. IF YOU DO NOT WISH TO BE SUBJECT TO ARBITRATION ON A RETROACTIVE BASIS AND AS TO ANY FUTURE CLAIMS AND YOU HAVE NOT PREVIOUSLY AGREED TO AN ARBITRATION PROVISION IN CONNECTION WITH YOUR USE OF OUR SERVICE, YOU MAY OPT OUT OF THE ARBITRATION PROVISION WITHIN THIRTY (30) DAYS BY FOLLOWING THE INSTRUCTIONS PROVIDED AT THE END OF THE SECTION TITLED "BINDING ARBITRATION AND CLASS WAIVER." SEE SECTION 16 OF THESE TERMS, BELOW. </b> </div>
        <div class="content"><b>PLEASE READ THE FOLLOWING TERMS OF USE, THE GameWonder PRIVACY POLICY AND THE OFFICIAL SWEEPSTAKES PROMOTIONAL RULES CAREFULLY BEFORE USING THE SERVICES OFFERED IN CONNECTION WITH ANY GameWonder SERVICES OR WEBSITE OR APPLICATION. YOU AGREE THAT YOUR CONTINUED USE OR ACCESS OF THE SITE OR SERVICES SHALL BE SUBJECT TO THESE TERMS OF USE, WHICH FURTHER INCORPORATE AND INCLUDE THE <span style="color: blue; cursor: pointer;" @click="onClickP">PRIVACY POLICY</span>, AND THE OFFICIAL SWEEPSTAKES PROMOTIONAL RULES.</b> </div>
        <div class="content">
            These Terms of Use ("the Terms" or "TOU") form a binding agreement between You and GAME WONDER LIMITED ("GameWonder," "Us, "We" or "Sponsor") which provides all of the terms and conditions governing Your access and use of <span @click="onClickHF" style="color: blue; text-decoration: underline; cursor: pointer">GameWonder</span> and any related applications (the "Site") as well as Your creation of Your User Account, use of the Games on the Site, participation in any Sweepstakes Promotions, and any transactions or dealings with Us in any way (collectively, the "Services"). 
        </div>
        <div class="content">
            You represent and warrant that You have the right, authority, and capacity to accept these Terms and to abide by them, that You are of legal age and that You have fully read and understood the Terms. You must read these Terms carefully in their entirety <span style="color: black">before checking the box for acceptance of these Terms.</span> By checking the acceptance, or by accessing the Games or creating a User Account, You confirm that You have read and agree to be bound by these Terms.
        </div>
        <div class="title1">
            1. Changes to Terms of Use and Incorporated Policies
        </div>
        <div class="content">
            1.1. From time to time, We may modify or amend these Terms. If we do so, any such modifications or changes shall be reflected in the TOU on the Site at Terms of Use. We will also notify You by email regarding any material changes to the TOU. Whether You receive or review such notifications, You agree that You will be bound by any such changes and that it shall be Your responsibility to check the Terms Of Use as posted on the Site prior to accessing the Site or partaking in any Services. Your further use of the Services after any changes are posted shall constitute further consent and agreement to the Terms as changed or amended.
        </div>
        <div class="content">
            1.2.  From time to time, We may also modify or amend the incorporate our Privacy Policy, Sweepstakes Rules and Player Safety Policy (collectively the "Incorporated Policies"). If we do so, any such modifications or changes shall be reflected in the Incorporated Policies as posted on the Site. You agree that You will be bound by any such changes and that it shall be Your responsibility to check the Incorporated Policies as posted on the Site prior to accessing the Site or partaking in any Services. Your further use of the Services after any changes are posted shall constitute further consent and agreement to the Incorporated Policies as changed or amended.
        </div>
        <div class="content">
            1.3.  If You have any questions about these Terms or the Incorporated Policies, please contact customer support using the Contact Us link on the Site.
        </div>
        <div class="content">
            1.4. In the event of any conflict between the Terms and the Incorporated Policies, the Terms shall control.
        </div>
        <div class="title1">
            2. Limited Revocable License
        </div>
        <div class="content">
            2.1. Virtual Coins. The Services may include a virtual, in-game tokens ("Virtual Coins") including, but not limited to chips, coins, credits, or points, that may be provided for free through the Services for "real world" money where legally permissible. On the Site, Games are currently played with Gold Coins and the Sweepstakes Promotions utilize Sweepstakes Coins. Subject to Your agreement and continuing compliance with these Terms, we grant You a limited, personal, non-exclusive, non-transferable, non-sublicensable, revocable, license to access and use the Service, solely for Your personal, private entertainment and no other reason. Other than a limited, personal, revocable, non-transferable, non-sublicensable license to use the Virtual Coins with the Services, You have no right or title in or to any such Virtual Coins appearing or originating with the Services, or any other attributes associated with use of the Services or stored within the Services. You acknowledge and agree that Your license to use the Service is limited by these Terms and if You do not agree to, or act in contravention of, these Terms, Your license to use the Service may be immediately terminated. We have the absolute right to manage, regulate, control, modify and/or eliminate such Virtual Coins as it sees fit in its sole discretion to the extent legally permissible, and We shall have no liability to You or anyone for the exercise of such rights.
        </div>
        <div class="content">
            2.2. The transfer or sale of Virtual Coins by You to any other person is strictly prohibited. Nor may You sell or assign Your User Account to any other person under any circumstances. Any attempt to do so is in violation of these Terms, will result in in closure and forfeiture of the User Account, and may result in a lifetime ban from the Services and possible legal action.
        </div>
        <div class="content">
            2.3. You understand and agree that any sales of Virtual Coins are final and that We are not required to provide a refund for any reason. All Virtual Coins are forfeited if Your account is terminated or suspended for any reason, in our sole and absolute discretion or if the Services are no longer available. To the extent legally permissible, if Your account, or a particular subscription for the Service associated with Your account, is terminated, suspended and/or if any Virtual Coins are selectively removed or revoked by Us from Your account, no refund will be granted, no Virtual Coins will be credited to You or converted to cash or other forms of reimbursement.
        </div>
        <div class="content">
            2.4. These Terms do not grant You any right, title or interest in the Service.
        </div>
        <div class="content">
            2.5. This Service is licensed, not sold, to You. You agree that we and our licensors own all rights, title and interest in and to the Service, including all intellectual property rights therein as further specified below in Section 10, and that we retain ownership of the Service even after installation on Your device. You agree not to delete or in any manner alter the copyright, trademark or other proprietary rights notices or markings which may appear on the Service.
        </div>
        <div class="content">
            2.6. Except as identified and specified in these Terms, You agree not to:
        </div>
        <div class="content">
            2.6.1. sell, rent, distribute, transfer, license, sub-license, lend or otherwise assign any rights of any part of the Service to any third party;
        </div>
        <div class="content">
            2.6.2. copy, modify, create derivative works of the Service (including but not limited to any software that forms part of the Service), including, without limitation, making adaptations or modifications to the Service;
        </div>
        <div class="content">
            2.6.3. reproduce the Service or any part in any form or by any means;
        </div>
        <div class="content">
            2.6.4. exploit the Service in any unauthorized way whatsoever, including without limitation, by trespass or burdening network capacity;
        </div>
        <div class="content">
            2.6.5. disassemble, decompile, reverse engineer, or attempt to derive the source code of the Service, in whole or in part, or permit or authorize a third party to do so, except to the extent such activities are expressly permitted by law;
        </div>
        <div class="content">
            2.6.6. make the Service available to multiple users by any means, including by uploading the Service to a file-sharing service or other type of hosting service or by otherwise making the Service available over a network where it could be used by multiple devices at the same time;
        </div>
        <div class="content">
            2.6.7. misrepresent the source of ownership of the Service;
        </div>
        <div class="content">
            2.6.8. scrape, build databases or otherwise create permanent copies of any content derived from the Service; or
        </div>
        <div class="content">
            2.6.9. use the Service in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party.
        </div>
        <div class="title1" id="Eligibility">
            3. Eligibility
        </div>
        <div class="content">
            Your eligibility for continued use of the Service is contingent on Your ongoing compliance with these Terms, in particular:
        </div>
        <div class="content">
            3.1. You are over 18 years of age or the minimum legal age of majority whichever is higher in the jurisdiction of Your residence and are, under the laws of the jurisdiction(s) applicable to You, You are legally allowed to participate in the Games and access the Service;
        </div>
        <div class="content">
            3.2. You understand and accept that we are unable to provide You with any legal advice or assurances and that it is Your sole responsibility to ensure that at all times You comply with the laws that govern You and that You have the complete legal right to use the Service;
        </div>
        <div class="content">
            3.3. You will monitor Your User Account and ensure that no child under the age of 18 can access the Service using Your User Account. You accept full responsibility for any unauthorized use of the Service by minors and You acknowledge that You are responsible for any use of the Service;
        </div>
        <div class="content">
            3.4. You do not reside in and will not access the Games or Service from the states of Washington, Idaho, Michigan, Nevada (USA) or any jurisdiction outside the United States (the "Restricted Territories");
        </div>
        <div class="content">
            3.5. You participate in the Games strictly in Your personal capacity for recreational and entertainment purposes only;
        </div>
        <div class="content">
            3.6. all information that You provide to us during the term of validity of these Terms is true, complete and correct;
        </div>
        <div class="content">
            3.7. You will not be involved in any fraudulent or other unlawful activity in relation to Your participation in any of the Games and You will not use any software-assisted methods or techniques (including but not limited to "bots" designed to play automatically) for Your participation in any of the Games. We reserve the right to invalidate any participation in the event of such behavior;
        </div>
        <div class="title1">
            4. Your User Account
        </div>
        <div class="content">
            4.1. Only one User Account is permitted per person. In the event You open or try to open more than one User Account, all User Accounts You have opened or try to open may then be terminated or suspended any prizes or Virtual Coins balances may be voided.
        </div>
        <div class="content">
            4.2. If You lose access to Your User Account , You must not register a new User Account. Rather You must contact customer support via the Contact Us form to have Your account status updated.
        </div>
        <div class="content">
            4.3. You are required to keep Your personal details up to date. If You change Your address, email, phone number or any other contact details or personal information please contact customer support. The name that You provide to us at registration must be match any identification You provide for Your User Account verification.
        </div>
        <div class="content">
            4.4. During the registration process You will be required to select a password unless (i) You login to Your User Account using the Facebook® login facility in which case Your Facebook® password will apply or (ii) You login to Your User Account using the Google® login facility in which case Your Google® password will apply.
        </div>
        <div class="content">
            4.5. You confirm that You will not share Your User Account or password with another person or let anyone else access or use Your User Account without our written permission. You will not access or use a User Account which has been rented, leased, sold, traded, or otherwise transferred from the account creator without our written permission.
        </div>
        <div class="content">
            4.6. If You become aware that or have reasons to suspect that the security of Your User Account may have been compromised, including loss, theft or unauthorized disclosure of Your password and User Account details, You must notify us immediately.
        </div>
        <div class="content">
            4.7. You are responsible for maintaining the confidentiality of Your User Account and accept responsibility for all uses of the User Account.
        </div>
        <div class="content">
            4.8. We reserve the right to close Your User Account if it is inactive for a period of sixty days or longer. You agree that We are not required to give notice to You prior to taking such action, although we may choose to do so in our sole discretion.
        </div>
        <div class="content">
            4.9. If You wish to close Your User Account, You may do so at any time by contacting customer support. Closing Your User Account will forfeit all continued access to and right to use, enjoy or benefit from any Virtual Coins associated with Your User Account.
        </div>
        <div class="content">
            4.10. We reserve the right to refuse or close a User Account at our sole discretion.
        </div>
        <div class="content">
            4.11. You may close Your User Account at any time by contacting customer support.
        </div>
        <div class="content">
            4.12. User Account registrations may be limited to a one account registrations per IP address at our sole discretion.
        </div>
        <div class="title1">
            5. Games and Contests
        </div>
        <div class="content">
            5.1. In addition to these Terms, Games offered on the Service may have their own rules which are available on the Service. It is Your responsibility to read the rules of a Game before playing. You must familiarize Yourself with the applicable terms of play and read the relevant rules before playing any Game.
        </div>
        <div class="content">
            5.2. In addition to these Terms, Sweepstakes Promotions offered through the Service have their own rules which are available on the Service. For current Rules, see official Sweepstakes Rules. It is Your responsibility to read the rules of a Game before playing. You must familiarize Yourself with the applicable terms of play and read the relevant rules before playing any Game.
        </div>
        <div class="title1">
            6. Complaints
        </div>
        <div class="content">
            6.1. If You have a complaint in relation to the Service, You may contact us by selecting the "Contact Us" link on the Site and filling in the form.
        </div>
        <div class="content">
            6.2. Customer complaints/claims of any nature must be submitted for consideration within three (3) months of the issue giving rise to the complaint.
        </div>
        <div class="content">
            6.3. To protect Your privacy, all communications between You and us should be carried out using or referencing the email address that You used to register Your User Account for the Service. Failure to do so may result in our response being delayed. The following information must be included in any written communication with us (including a complaint):
        </div>
        <div class="content">
            6.3.1. Your user ID;
        </div>
        <div class="content">
            6.3.2. Your first and last name, as registered on Your User Account;
        </div>
        <div class="content">
            6.3.3. detailed explanation of the complaint/claim;
        </div>
        <div class="content">
            6.3.4. any specific dates and times associated with the complaint/claim (if applicable).
        </div>
        <div class="content">
            6.4. Failure to submit a written communication with the information outlined above may result in a delay in our ability to identify and respond to Your complaint/claim in a timely manner. Upon receipt, we will endeavor to reply to Your communication within seventy-two (72) hours.
        </div>
        <div class="content">
            6.5. Further, best efforts will be made to resolve any complaint/claim promptly, if for some reason You are not satisfied with the resolution of Your complaint/claim, You may pursue arbitration as set out below in Section 16.
        </div>
        <div class="title1">
            7. Promotions
        </div>
        <div class="content">
            7.1. All promotions including Sweepstakes Promotions, contests, and special offers are subject to these Terms, the Official Sweepstakes Rules and any additional terms that may be published at the time of the promotion, contest or special offer.
        </div>
        <div class="content">
            7.2. We reserve the right to withdraw or alter any such promotions without prior notice to You at our sole discretion.</div>
        <div class="content">
            7.3. We reserve the right at our sole discretion and without any requirement to give reasons to exclude You from any promotions, contests or special offers that are offered from time to time.</div>
        <div class="content">
            7.4. We reserve the right to exclude You from any promotions, contests or special offers if we believe that You have tried to enter by using more than one User Account or are otherwise engaging in any fraudulent or illegal activity (including participation that would be in breach of the law in Your local jurisdiction), whether or not You would have or might have won but for such activity. Where multiple entries/accounts have been used, we reserve the right to suspend these accounts and withhold any promotional benefits.</div>
        <div class="content">
            7.5. You confirm that You grant us an irrevocable, perpetual, worldwide, non-exclusive, royalty-free License to use in whatever way we see fit, and without further acknowledgement of You as the author, any content You post or publish as part of a promotion, contest or competition.
        </div>
        <div class="title1">
            8. Verification
        </div>
        <div class="content">
            8.1. You acknowledge that we are entitled to conduct any verification checks (including but not limited to credit background checks) that we may reasonably require and/or that are required of us under applicable laws and regulations or by relevant regulatory authorities. You agree to comply with all verification checks in a timely manner.</div>
        <div class="content">
            8.2. You agree that we may restrict Your opening or use of a User Account pending any verification checks having been completed to our satisfaction.</div>
        <div class="content">
            8.3. The documents required may include identification documentation (including photo identification) including but not limited to a government issued identification document such as a passport or driver’s license; a utility bill that matches the address registered on Your User Account; and source of wealth or source of funds documentation such as a payslip or bank statement.</div>
        <div class="content">
            8.4. In the event that any verification check cannot be completed because You have failed to provide any document, we requested from You in the form that we require within 40 days’ from the date the document was first requested, then we may, in our sole discretion, deactivate or otherwise restrict Your User Account.</div>
        <div class="content">
            8.5. You acknowledge and agree that we may use third party service providers to run external identification, location verification and other verification checks based on the information provided by You from time to time. You must enable and allow "Locations Services" on Your device or PC in order to operate the Services or access your User Account.
        </div>
        <div class="title1">
            9. Intellectual Property
        </div>
        <div class="content">
            9.1. These Terms confer only the right to use the Service and they do not convey any rights of ownership in or to the Service.
        </div>
        <div class="content">
            9.2. All rights, title and interest, including without limitation any copyright, patent, trade secret or other intellectual property right in the Service will remain our sole property or where licensed from a third party their sole property.
        </div>
        <div class="content">
            9.3. Your use of the Games will not convey any ownership rights in the intellectual property to You. The titles, source and object codes, game client and server software, the "look and feel" of the Games, sounds, musical compositions, audio-visual effects, concepts and methods of operation, layout, text, data, User Accounts, themes, objects, characters and character likenesses, character names and character profile information, stories, dialogue, catch phrases, locations, artwork, animations files, images, graphics, documentation, gaming history and recording of game play, transcripts of any chat rooms, and moral rights, whether registered or not, and all applications related to the above will remain vested in us or any third party supplier of the Games.
        </div>
        <div class="content">
            9.4. Notwithstanding, anything to the contrary in these terms, You acknowledge and agree that You shall have no ownership or other property interest in the User Account, and You further acknowledge and agree that all rights in and to the user account are and shall forever be owned by and inure to the benefit of us.
        </div>
        <div class="title1">
            10. Responsibility for User Generated Content
        </div>
        <div class="content">
            10.1. You are responsible for complying with all laws applicable to Your User Content. You agree not to submit to the Service, or send to other users of the Service, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, illegal material or any material that infringes or violates another party’s rights.
        </div>
        <div class="content">
            10.2. You will not provide inaccurate, misleading or false information to us or to any other user of the Service. If information provided to us, or another user of the Service, subsequently becomes inaccurate, misleading or false, You will notify us of such change immediately.
        </div>
        <div class="content">
            10.3. We may, in our sole discretion, delete any User Content without notice but are under no obligation to do so. We have no responsibility for the conduct of any user in the Service, including any User Content submitted in the Service. We assume no responsibility for monitoring the Service for inappropriate content or conduct. Your use of the Service is at Your own risk.
        </div>
        <div class="content">
            10.4. You hereby grant us the sole and exclusive, irrevocable, sub-licensable, transferable, worldwide, royalty-free license to reproduce, modify, create derivative works from, publish, distribute, sell, transfer, transmit, publicly display and use any User Content and to incorporate the same in other works in any form, media, or technology now known or later developed.
        </div>
        <div class="content">
            10.5. You further hereby grant to us the unconditional, right to use and exploit Your name, likeness and any other information or material included in any User Content and in connection with any User Content or Your User Account, without any obligation to You. You waive any rights of attribution and/or any moral rights You may have in Your User Content, regardless of whether Your User Content is altered or changed in any manner except as prohibited by law.
        </div>
        <div class="content">
            10.6. You acknowledge and agree that all User Content whether publicly posted or privately transmitted to the Service is at Your sole responsibility and risk. We disclaim any responsibility for the backup and/or retention of any User Content transmitted to the Service.
        </div>
        <div class="content">
            10.7. Prohibited Content; User Content that is prohibited in the Service includes, but is not limited to:
        </div>
        <div class="content">
            10.7.1. User Content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;
        </div>
        <div class="content">
            10.7.2. Harassing User Content;
        </div>
        <div class="content">
            10.7.3. User Content of a sexual or offensive nature;
        </div>
        <div class="content">
            10.7.4. User Content that promotes terrorism or religious hatred;
        </div>
        <div class="content">
            10.7.5. User Content that promotes illegal activities or conduct that is abusive, threatening, obscene or defamatory;
        </div>
        <div class="content">
            10.7.6. User Content of commercial nature without authorization from us; or
        </div>
        <div class="content">
            10.7.7. Content promoting the services of another business or competitor.
        </div>
        <div class="content">
            10.8. If You see any material in the Service that in Your belief is offensive, hateful, harassing or that You otherwise think is prohibited, You may notify us by contacting us at admin@gamewonder.vip.
        </div>
        <div class="title1">
            11. Third Party Websites & Content
        </div>
        <div class="content">
            11.1. The Service may contain links to content owned and/or operated by third parties, for instance third parties who may invite You to participate in promotional offers or rewards programs or third-party advertisers. Any separate charges or obligations that You may incur in Your dealings with these third parties are Your sole responsibility. We are not responsible for any such third-party services or content and do not have control over any materials made available therein.
        </div>
        <div class="content">
            11.2. Our inclusion of a link to a third-party website, services or content in the Service does not imply our endorsement, advertising, or promotion of such websites, services or content or any materials available and we make no guarantee as to the content, functionality, or accuracy of any third-party website.
        </div>
        <div class="content">
            11.3. By accessing a third-party website, services or content You accept that we do not exercise any control over such websites, services or content and have no responsibility for them. The third-party sites may collect data or solicit personal information from You. We are not responsible for privacy policies, or for the collection, use or disclosure of any information those sites may collect. It is always best to read and understand the terms of service and privacy policy applicable to any third-party website, services or content You may access.
        </div>
        <div class="content">
            11.4. We do not endorse, do not assume and will not have any liability or responsibility to You or any other person for any third-party products, services, materials or websites. Please note that the relevant third party is fully responsible for all goods and services it provides to You and for any and all damages, claims, liabilities and costs it may cause You to suffer, directly or indirectly, in full or in part.
        </div>
        <div class="content">
            11.5. If You use third party social networking websites to discuss the Service such as Facebook® and Twitter®, You acknowledge and agree that:
        </div>
        <div class="content">
            - any content that You post on such social networking sites are subject to the relevant terms and conditions of that website;
        </div>
        <div class="content">
            - You will not post any comments that are false, misleading or deceptive or defamatory to us, our employees, agents, officers or other Players; and
        </div>
        <div class="content">
            - we are not responsible or liable for any comments or content that You or others post on social networking sites.
        </div>
        <div class="title1">
            12. Infringement Notice
        </div>
        <div class="content">
            12.1. We respect the intellectual property rights of others and require that our users do the same. If You believe Your work has been copied in a manner that constitutes copyright infringement, or You believe Your rights are otherwise infringed or violated by anything on the Site, please notify us by sending an email to the following address: admin@gamewonder.vip.
        </div>
        <div class="content">
            12.2. In order for us to more effectively assist You, applicable law requires that the notification must include all of the following:
        </div>
        <div class="content">
            - A physical or electronic signature of the owner of the right claimed to be infringed or the person authorized to act on the owner’s behalf;
        </div>
        <div class="content">
            - A description of the copyrighted work or other right You claim has been infringed or violated;
        </div>
        <div class="content">
            - Information reasonably sufficient to locate the material in question on the Site;
        </div>
        <div class="content">
            - Your name, address, telephone number, e-mail address and all other information reasonably sufficient to permit us to contact You;
        </div>
        <div class="content">
            - A statement by You that You have a good faith belief that the disputed use is not authorized by the rightful owner, its agent or the law; and
        </div>
        <div class="content">
            - A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the owner of the right claimed to be infringed or violated or are authorized to act on behalf of the owner.
        </div>
        <div class="content">
            12.3. Before You file Your notification, please carefully consider whether or not the use of the copyrighted material at issue is protected by the Fair Use doctrine. If You file a notification when there is no infringing use, You could be liable for costs and attorneys’ fees.
        </div>
        <div class="title1">
            13. Disruptions, Errors & Omissions
        </div>
        <div class="content">
            13.1. Disclaimer of Warranties
            THE SERVICES, IN WHOLE AND IN PART (INCLUDING, WITHOUT LIMITATION, ALL CONTENT, AND USER MATERIALS), ARE PROVIDED, TRANSMITTED, DISTRIBUTED, AND MADE AVAILABLE "AS IS" AND "AS AVAILABLE" WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY: (A) THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; (B) THAT DEFECTS OR ERRORS IN THE SERVICES WILL BE CORRECTED; (C) THAT THE SERVICES WILL BE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS; (D) AS TO THE QUALITY, ACCURACY, COMPLETENESS AND VALIDITY OF ANY INFORMATION OR MATERIALS IN CONNECTION WITH THE SERVICES; (E) THAT YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS; OR (F) THAT TRANSMISSIONS OR DATA WILL BE SECURE.
        </div>
        <div class="content">
            13.2 Exceptions
            SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER, EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES, LIABILITIES AND DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS, EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, OUR WARRANTIES AND LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
        </div>
        <div class="content">
            13.3. We are not liable for any downtime, server disruptions, errors, lagging, or any technical or political disturbance to the Service or Games, nor attempts by You to participate by methods, means or ways not intended by us.
        </div>
        <div class="content">
            13.4. We accept no liability for any damages or losses which are deemed or alleged to have arisen out of or in connection with any Service including, without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of a Service or any errors or omissions in the Service.
        </div>
        <div class="content">
            13.5. In the event of a Service system malfunction then all Game play on the Service will be void.
        </div>
        <div class="content">
            13.6. In the event of an error or malfunction in a Game, then all Game play resulting from the error or malfunction will be voided.
        </div>
        <div class="content">
            13.7. We reserve the right to remove any part of the Games from the Service at any time. Any part of the Games that indicate incorrect behavior affecting, game data, Virtual Coins balances, balances or other balances, that may be due to misconfiguration or a bug, will be cancelled and removed from the Service. Player balances and account details may be altered by us in such cases in order to correct any mistake.
        </div>
        <div class="content">
            13.8. We may temporarily suspend the whole or any part of the Service for any reason at our sole discretion. We may, but will not be obliged to, give You as much notice as is reasonably practicable of such suspension. We will restore the Service, as soon as is reasonably practicable, after such temporary suspension.
        </div>
        <div class="content">
            13.9. We reserve the right to declare participation in a Game void, partially or in full, if, in our sole discretion, we deem it obvious that there was an error, mistake, misprint or technical error on the pay-table, win-table, minimum or maximum stakes, odds or software.
        </div>
        <div class="content">
            13.10. If You are incorrectly awarded any Virtual Coins or prizes as a result of (a) any human error; (b) any bug, defect or error in the Service; or (c) the failure of any Games to operate in accordance with the rules of the relevant game, then we will not be liable to award You any such Virtual Coins or prizes and You agree that any such Virtual Coins or prizes that have been awarded in error to Your User Account will be voided.
        </div>
        <div class="content">
            13.11. We retain absolute discretion in the event of a discrepancy between the result showing on a user’s device and the server software. Such discretion includes the authority to recognize the result showing on the server software as the official and governing result.
        </div>
        <div class="title1">
            14. Indemnity and Limitation of Liability
        </div>
        <div class="content">
            14.1. To the maximum extent permitted by applicable law, we and our subsidiaries, affiliates, officers, employees, agents, partners and licensors will not be liable for any direct, indirect, incidental, special, consequential or punitive damages, including without limitation, personal injury or property damage, loss of data, loss of use, loss of anticipated savings, accrued but wasted expenditure, cost of procurement of substitute goods or services, loss of good-will, or other intangible losses, resulting from:
        </div>
        <div class="content">
            14.1.1. Your access to or use of or inability to access or use the service;
        </div>
        <div class="content">
            14.1.2. any conduct or content of any third party on the service, including without limitation any content, products or services provided by any advertisers, affiliate advertising networks, rewards program operators we may cooperate with or any other users of the service;
        </div>
        <div class="content">
            14.1.3. any content and/or information obtained from the service or reliance upon the service or any part thereof;
        </div>
        <div class="content">
            14.1.4. unauthorized access or use or alteration of any material or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we knew of or ought to have known of the possibility of such damages.
        </div>
        <div class="content">
            14.2. In some jurisdictions the applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages, so the above limitation or exclusion may not apply to You. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. Nothing in these terms removes or supersedes Your rights as a consumer based on mandatory provisions of law.
        </div>
        <div class="content">
            14.3. To the maximum extent permissible under applicable law, our total liability and/or the liability of our affiliates is limited to the total amount You have paid us in the one hundred and eighty (180) days immediately preceding the date on which You first assert any such claim.
        </div>
        <div class="content">
            14.4. We are not under any circumstances liable for any damages relating to or caused by any viruses, bugs, human action or inaction of any computer system, phone line, hardware, software, or program malfunctions, or any other errors, failures, or delays in computer transmissions or network, connections on account of Your access to or use of the services. We cannot and do not guarantee continuous, uninterrupted, or secure access to the Service.
        </div>
        <div class="content">
            14.5. We are not under any circumstances liable for any damages, claims, liabilities or costs that You may incur or suffer in connection with any content, products or services offered by any third parties we cooperate with.
        </div>
        <div class="content">
            14.6. You waive and release us and our subsidiaries, affiliates, partners, officers, directors, employees and agents from any liabilities arising from or related to any act or omission of such third parties in connection with Your use of the service.
        </div>
        <div class="content">
            14.7. You agree to indemnify and hold us harmless from any claim or demand, including attorneys’ fees, made by any third party due to or arising out of Your use of or access to the Service, Your breach of these Terms, or Your violation of any law or the rights of a third party. Nothing in these Terms shall be deemed to exclude or limit Your liability in respect of any indemnity given by You under these Terms.
        </div>
        <div class="content">
            14.8. Nothing in these terms will operate so as to exclude our liability for death or personal physical injury that is directly and proximately caused by our negligence or willful misconduct.
        </div>
        <div class="title1">
            15. Dispute resolution and Agreement to Arbitrate all Disputes
        </div>
        <div class="content">
            15.1. Binding Arbitration and Class Waiver.  
            PLEASE READ THESE "BINDING ARBITRATION" AND "CLASS WAIVER" PROVISIONS CAREFULLY, BECAUSE THEY REQUIRE YOU TO ARBITRATE ALL DISPUTES WITH GameWonder AND LIMIT THE MANNER IN WHICH YOU CAN SEEK RELIEF. THIS PROVISION APPLIES TO ANY CLAIMS YOU MAY CURRENTLY POSSESS AND ANY CLAIMS YOU MAY RAISE IN THE FUTURE. WHILE YOU MUST AGREE TO THESE TERMS OF USE IN ORDER TO USE THE SERVICES, IF YOU HAVE NOT PREVIOUSLY AGREED TO AN ARBITRATION PROVISION IN CONNECTION WITH YOUR USE OF OUR SERVICE, THERE IS AN OPTION, DESCRIBED BELOW, TO OPT OUT OF THE ARBITRATION AND CLASS WAIVER PROVISIONS. THE OPTION TO OPT-OUT IS TIME-LIMITED TO THIRTY (30) DAYS AND REQUIRES YOUR IMMEDIATE ATTENTION. 
        </div>
        <div class="content">
            THESE PROVISIONS GENERALLY PRECLUDE YOU FROM BRINGING ANY CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST GameWonder. THEY ALSO PRECLUDE YOU FROM PARTICIPATING IN OR RECOVERING RELIEF UNDER ANY PAST, PENDING, OR FUTURE CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST GameWonder BY SOMEONE ELSE. ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. 
        </div>
        <div class="content">
            Scope of Arbitration Provision. You and GameWonder agree that any past, pending, or future dispute, claim or controversy arising out of or relating to your access to or use of any GameWonder Site (including Services) or to these Terms of Use (including without limitation any dispute concerning the breach, enforcement, construction, validity, interpretation, enforceability, or arbitrability of these Terms of Use) (a "Dispute"), shall be determined by arbitration, including claims that arose before acceptance of any version of these Terms containing an arbitration provision, except that you and GameWonder are NOT required to arbitrate any Dispute in which either party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. In addition, in the event of any dispute concerning the scope or applicability of the Arbitration Provisions of these Terms, You and GameWonder agree that the arbitrator exclusively shall have the power to rule on his or her own jurisdiction over the Dispute, including any objections with respect to the existence, scope or validity of the arbitration agreement or to the arbitrability of the claims or counterclaims presented as part of the Dispute.
        </div>
        <div class="content">
            The parties acknowledge that the agreement in this Section to arbitrate any Disputes on an individual and case-by-case basis is a separate agreement for purposes of the Federal Arbitration Act in addition to the Terms. The alleged invalidity of the Terms of Use shall have no effect upon the validity of our mutual agreement to arbitrate any Disputes under this Section. In addition, If any portion of this section entitled "Dispute resolution and Agreement to Arbitrate all Disputes" is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect consistent with Section 18.5 of these Terms.
            Waiver of Class Relief. Whether the dispute is heard in arbitration or in court, you agree that you and GameWonder will not commence against the other a class action, class arbitration, mass action or other representative action or proceeding, and shall not otherwise participate in such actions. You and GameWonder are each waiving respective rights to participate in a class action. By accepting this agreement, you give up your right to participate in any past, pending or future class action or any other consolidated or representative proceeding, including any existing as of the date of you agreed to these terms of use. 
        </div>
        <div class="content">
            Whether to agree to arbitration is an important decision. It is your decision to make and you are not required to rely solely on the information provided in these terms of use. You should take reasonable steps to conduct further research and to consult with counsel (at your expense) regarding the consequences of your decision.
            OPTION TO OPT OUT. IF YOU HAVE NOT PREVIOUSLY AGREED TO AN ARBITRATION PROVISION IN CONNECTION WITH YOUR USE OF OUR SERVICE, YOU MAY OPT OUT OF THESE ARBITRATION AND CLASS ACTION PROVISIONS BY FOLLOWING THE INSTRUCTIONS BELOW. IF YOU DO NOT OPT-OUT, THESE TERMS WILL APPLY RETROACTIVELY TO ALL CLAIMS YOU MAY POSSESS, WHETHER ASSERTED TO DATE OR NOT. 
        </div>
        <div class="content">
            PROCEDURE TO OPT OUT OF ARBITRATION. IF YOU DO NOT WISH TO AGREE TO THIS SECTION OF THE TERMS OF USE REQUIRING ARBITRATION AND CLASS ACTION WAIVER AND YOU HAVE NOT PREVIOUSLY AGREED TO AN ARBITRATION PROVISION IN CONNECTION WITH YOUR USE OF OUR SERVICE, YOU MUST, WITHIN THIRTY (30) DAYS OF ENTERING THIS AGREEMENT, CLICK THE FOLLOWING LINK ARBITRATION OPT OUT AND ENTER ANY DETAILS REQUESTED. A REQUEST TO OPT-OUT AFTER THE 30 DAY PERIOD SHALL NOT BE EFFECTIVE.
        </div>
        <div class="content">
            Location of Arbitration and Applicable Rules. You and GameWonder agree that such arbitration shall occur in Wilmington, Delaware. You may appear in such proceedings telephonically. You and GameWonder agree that such arbitration shall be conducted by a single arbitrator in accordance with the Commercial Rules of the American Arbitration Association ("AAA"), as modified by these Terms of Use.
        </div>
        <div class="content">
            Authority of Arbitrator. With the exception of class procedures and remedies as discussed above under "Waiver of Class Relief," the arbitrator shall have the authority to grant any remedy that would otherwise be available in court.
        </div>
        <div class="content">
            Confidentiality. You and GameWonder shall maintain the confidential nature of the arbitration proceedings and the arbitration award, including the arbitration hearing, except as may be necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in connection with a court application for a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision.
        </div>
        <div class="content"> 
            Allocation of Arbitration Fees. If you assert a Dispute as an individual, you will only be required to pay arbitration fees of $250 in connection with any arbitration under this section, and GameWonder will bear all other costs charged by AAA or the arbitrator up to $5,000. You will still be responsible for paying your own attorneys’ fees.
        </div>
        <div class="content">
            Each party shall bear its own costs in the arbitration proceeding. If any portion of this section entitled "Binding Arbitration and Class Waiver" is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect.
        </div>
        <div class="title1">
            16. One Year Limitations Period
        </div>
        <div class="content">
            16.1. Disputes and Claims Must be Raised Within One Year. We both agree that any claims or lawsuits, regardless of form, arising out of or related to the Service or these Terms of Use must be filed within ONE (1) YEAR of the action, omission, event or occurrence giving rise to the claim or suit, after which such claims will be time-barred and prohibited, without regard to any longer period of time which may be provided by any period of limitation or repose by law or statute.
        </div>
        <div class="title1">
            17. Miscellaneous
        </div>
        <div class="content">
            17.1. Entire Agreement. These Terms constitute the entire agreement between You and us with respect to Your use of the Service and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between You and us with respect to Your participation.
        </div>
        <div class="content">
            17.2. Tax. You are solely responsible for any taxes which apply to Your use of the Service.
        </div>
        <div class="content">
            17.3. Force Majeure. We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms that is caused by events outside of our reasonable control.
        </div>
        <div class="content">
            17.4. Severability. If for any reason any provision of the Terms or portion thereof, is found by a court of competent jurisdiction to be unlawful, void, or unenforceable, that part of Terms will be deemed severable and shall not affect the validity and enforceability of the remainder of these Terms which shall continue in full force and effect.
        </div>
        <div class="content">
            17.5. Assignment. These Terms are personal to You, and are not assignable, transferable or sub-licensable by You except with our prior written consent. We reserve the right to assign, transfer or delegate any of our rights and obligations hereunder to any third party without notice to You.
        </div>
        <div class="content">
            17.6. Entire Agreement. These Terms contain the entire understanding between You and us, and supersede all prior understandings between You and us relating to the subject matter.
        </div>
        <div class="content">
            17.7. Business Transfers. In the event we undergo a change of control, merger, acquisition, or sale of assets, Your User Account and associated data may be part of the assets transferred to the purchaser or acquiring party.
        </div>
        <div class="content">
            17.8. Waiver. Our failure to assert breach or a violation of these Terms or otherwise failure to exercise any rights under these Terms shall not constitute or be deemed a waiver or forfeiture of such rights or a waiver or forfeiture of such rights in the future.
        </div>
        <div class="title1">
            18. Applicable Law and Jurisdiction
        </div>
        <div class="content">
            18.1. GOVERNING LAW. The parties agree that this Agreement and related Services are governed by and shall be construed in accordance with the laws of the State of Delaware USA without regard to its principles of conflicts of law.
        </div>
        <div class="content">
            18.2. Subject to the arbitration previsions contained in Section 16 the parties agree that any dispute, controversy, or claim arising out of or in connection with these Terms, or the breach, termination or invalidity of these Terms, will be submitted exclusively to the courts in Delaware, and You and we consent to the venue and personal jurisdiction of those courts. Notwithstanding the foregoing, any motion to compel arbitration or to enforce an arbitral award issued hereunder may be brought before any court of competent jurisdiction.
        </div>
    </div>
</template>
<script>
export default {
    name:"TermsOfUseView",
    title: "Terms of Use",
    methods: {
        onClickHF: function(){
            this.$router.push("/")
        },
        onClickP: function(){
            this.$router.push("Privacy")
        },
    },
    mounted() {
        this.$nextTick(() => {
        // 优先从 query 参数获取
        const scrollTarget = this.$route.query.scrollTo;
        
        if (scrollTarget) {
            const target = document.getElementById(scrollTarget);
            target?.scrollIntoView({ behavior: "auto" });
        }
        });
    }
}
</script>
<style lang="scss" scoped>
*{
    font-size: 18px;
}
.title {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 23px
}

.title1 {
    display: block;
    text-align: left;
    font-weight: bold;
}

.version {
    display: block;
    text-align: left;
}

.content {
    display: block;
    text-align: left;
}

div {
    margin: 12px;
}
</style>