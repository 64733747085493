import Vue from 'vue'
// 引入路由第三方包
import VueRouter from 'vue-router'
// 在vue项目中使用vue-router进行管理
Vue.use(VueRouter)
 
// 引入组件
import HomeView from '@/views/HomeView.vue'
import TermsOfUseView from '@/views/TermsOfUseView.vue'
import PrivacyView from '@/views/PrivacyView.vue'
import SweepstakesView from '@/views/SweepstakesView.vue'
import ShopView from '@/views/ShopView.vue'
import ResponsibleView from '@/views/ResponsibleView.vue'
import PromotionEntryView from '@/views/PromotionEntryView.vue'
import CaliforniaPrivacyNoticeView from '@/views/CaliforniaPrivacyNoticeView.vue'
 
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      component: HomeView,
      title: "Hit Fortune"
    },
    {
      name: 'TermsOfUse',
      path: '/TermsOfUse',
      component: TermsOfUseView,
      title: "TermsOfUseView"
    },
    {
      path: '/Privacy',
      component: PrivacyView,
      title: '/Privacy',
    },
    {
      path: '/Sweepstakes',
      component: SweepstakesView,
      title: '/SweepstakesView',
    },
    {
      path: '/Responsible',
      component: ResponsibleView,
      title: '/ResponsibleView',
    },
    {
      path: '/Shop',
      component: ShopView,
      title: 'Shop',
    },
    {
      path: '/PromotionEntry',
      component: PromotionEntryView,
      title: 'PromotionEntryView',
    },
    {
      path: '/CaliforniaPrivacyNotice',
      component: CaliforniaPrivacyNoticeView,
      title: 'CaliforniaPrivacyNoticeView',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
    },
})

router.beforeEach(function(to, from, next){
  if (to.title){
    document.title = to.title;
  }else{
    document.title = "Hit Fortune";
  }
  next();
})
 
export default router