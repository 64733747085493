<template>
    <div style="margin-top: 30px; padding: 0 20px;">
        <div class="title">CALIFORNIA PRIVACY NOTICE</div>
        <div class="content">This CALIFORNIA PRIVACY NOTICE ("Notice") IS FOR CALIFORNIA RESIDENTS.</div>
        <div class="content">This Notice supplements our Privacy Policy.</div>
        <div class="content">
            California's Consumer Privacy Act of 2018 (the "CCPA") requires that we tell you about the categories of personal information collected by Game Wonder Limited, address:  ROOM 511,5/F,MING SANG IND BLDG,19-21 HING YIP STREET,KWUN  TONG,KLN,HONGKONG,KWUN TONG,KLN ("we", "us", "our", "Company", or Game Wonder Limited"), the sources from which we collect personal information, the categories of your personal information that we share, and the kinds of third parties with whom we share your personal information. We have set out that information in this Notice to comply with the CCPA and any terms defined in the CCPA have the same meaning when used in this Notice.
        </div>
        <div class="title1">
            Information We Collect
        </div>
        <div class="content">
            We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal information"). In particular, we collect the categories of personal information specified bellow. We may use, share, or disclose these categories of personal information for one or more of the indicated business or commercial purposes.
        </div>
        <table class="privacy-table">
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th>Collected</th>
            </tr>
            <tr>
              <td>A. Identifiers.</td>
              <td>A real name, alias/nickname, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>B. Personal information categories listed in the California Customer Records statute</td>
              <td>Including but not limited to name, social security number, address, telephone number, passport number, driver's license or state identification card number. Some personal information may also be included in the other categories.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>C. Protected classification characteristics under California or federal law</td>
              <td>Age (40 years or older), age range, citizenship, sex (including gender, gender identity, gender expression)</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>D. Commercial information</td>
              <td>Products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>E. Internet or other similar network activity</td>
              <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>F. Geolocation data</td>
              <td>Physical location or movements.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>G. Audio or video data</td>
              <td>Audio, electronic, or similar information.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>H. Biometric identifiers</td>
              <td>Face geometry (selfie).</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information</td>
              <td>Current or past job history or performance evaluations.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>J. Non-public education information (per the Family Educational Rights and Privacy Act)</td>
              <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>K. Inferences drawn from other personal information.</td>
              <td>Profile reflecting a person's preferences, characteristics, predispositions, behavior.</td>
              <td>YES</td>
            </tr>
          </table>
        <div class="content">
            The personal information listed above is obtained by us from the following sources:
        </div>
        <ul>
            <li>Directly from you. For example, from when you register on our website.</li>
            <li>Directly and indirectly from activity on our websites and mobile application. This includes:</li>
            <li>Device and connection information. For example, device-specific information such as hardware model, operating system information, browser information, IP address etc.</li>
            <li>Usage and log information. For example, information about your activity on our websites and mobile application, log files and reports, service-related and diagnostic information.</li>
            <li>From third parties that interact with us to provide you with the services. For example, Google, Facebook when you register, banks and financial institutions when you pay etc.</li>
        </ul>
        <div class="title1">
            Use of Personal Information
        </div>
        <div class="content">
            We may use or disclose the personal information we collect for one or more of the following business purposes as disclosed in our Privacy Policy:
        </div>
        <div class="content">
            (1) to provide our services via website or mobile application.
        </div>
        <div class="content">
            (2) to administer the entertainment video games and promotional games and advertising and promotional activities that we conduct from time to time (including prize notifications and prize deliveries), and
        </div>
        <div class="content">
            (3) to make reports and submissions to governmental agencies and branches, as required or permitted by law, and
        </div>
        <div class="content">
            (4) to comply with our legal and regulatory obligations
        </div>
        <div class="content">
            (5) to compile market and statistical reports based on aggregated information (but the reports do not contain personal information), and
        </div>
        <div class="content">
            (6) to allow us to better respond to the individual needs and preferences of people who access our website or mobile application, and
        </div>
        <div class="content">
            (7) to invite you to participate in surveys, contests, competitions, tournaments and other promotions, and
        </div>
        <div class="content">
            (8) for data analysis, audits, developing new products and services, identifying usage trends and determining the effectiveness of our promotional campaigns, and
        </div>
        <div class="content">
            (9) to process transactions, including (not limited to) fulfillment of prize awards, and product and service purchases, and
        </div>
        <div class="content">
            (10) to improve customers service and our services, and
        </div>
        <div class="content">
            (11) to verify the card ownership and ensure that such a card has been legitimately used on our website or mobile application, and
        </div>
        <div class="content">
            (12) to comply with and perform the obligation under the Affiliate Program, and
        </div>
        <div class="content">
            (13) to contact you about our services and send the notices.
        </div>
        <div class="content">
            We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
        </div>
        <div class="title1">
            Sharing Personal Information
        </div>
        <div class="content">
            We do not sell, trade, or rent your personal information.
        </div>
        <div class="content">
            We may only share your personal information with the service providers and other third parties for the purposes of provision of the services via Website or mobile application.
        </div>
        <div class="content">
            We may share your personal information only with trusted service providers and third parties who have contractually committed to protect your personal information in the same way that we do and who assist us in operating our Website, conducting our business, administrating our entertainment video games and promotional games, preparing market and statistical reports, and conducting other contests or promotions or providing services to you, so long as those parties agree by enforceable contract to keep this information confidential.
        </div>
        <div class="content">
            We may also share your personal information if this is required by the law subject of which we are and/or to comply with our legal obligations.
        </div>
        <div class="title1">
            Your Rights and Choices
        </div>
        <div class="content">
            Subject to certain restrictions, the CCPA gives California residents certain rights to request access to personal information that we have about you, to request deletion of personal information that we collect and maintain, and to opt-out of the sale of personal information about you. This section describes how you can exercise those rights and our process for handling your requests.
        </div>
        <div class="content">
            You have the right to know and request a copy of the following: (1) the categories of personal information we collected about you; (2) the categories of sources from which the personal information is collected; (3) the business or commercial purpose for collecting or, if applicable, selling, the personal information; (4) the categories of third parties with whom we share personal information; and (5) the specific pieces of personal information we have collected about you. You have the right to access the personal information we have collected about you during the past 12 months and information about our data practice.
        </div>
        <div class="content">
            You have the right to request that we delete personal information that we collected from you and retained, subject to certain exceptions.
        </div>
        <div class="content">
            To exercise the rights described above, please submit a verifiable consumer request to us on <a href="mailto:dataprivacy@funrize.com" target="_blank">admin@gamewonder.vip</a>.
        </div>
        <div class="content">
            Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information.
        </div>
        <div class="content">
            In order for your consumer request to be verifiable, you must send us the sufficient information that allows us to reasonably verify your identity. You will need to provide:
        </div>
        <ul>
            <li>full name</li>
            <li>email address</li>
            <li>date of birth</li>
            <li>address (if it was provided to us before your request)</li>
            <li>whether you are a California consumer pursuant to CCPA</li>
        </ul>
        <div class="content">
            You must describe your request with sufficient detail that allows us to properly understand, evaluate and respond to it.
        </div>
        <div class="content">
            In case when you need to know the specific personal information that we have collected about you, or to delete your personal information, you must identify that clearly.
        </div>
        <div class="content">
            If you decide to use an authorized agent, you must include written permission that you have designated that agent to make the respective request, or proof of the agent's power of attorney. We may follow up with you to verify your identity before processing your authorized agent's request.
        </div>
        <div class="content">
            We cannot respond to your request to provide any personal information if we cannot verify your identity using the information provided. For this purpose, we only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.
        </div>
        <div class="title1">
            Time and Format for Response
        </div>
        <div class="content">
            We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will send our written response to that account. If you do not have an account with us, we will send our written response by mail provided by you.
        </div>
        <div class="content">
            Any disclosures we provide in response to data access and/or data portability requests will only cover the 12-month period preceding the receipt of your request. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
        </div>
        <div class="content">
            We do not charge a fee to process or respond to your request unless your request is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
        </div>
        <div class="title1">
            Non-Discrimination
        </div>
        <div class="content">
            We will not discriminate against you for exercising any of your CCPA rights. This means that, unless permitted by the CCPA, your exercise of these rights will not result in Company: (1) denying you goods or services; (2) charging you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties; (3) providing you a different level or quality of goods or services; or (4) suggesting that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
        </div>
        <div class="title1">
            Authorized Agent
        </div>
        <div class="content">
            You may designate an authorized agent to submit a request with regard to your personal information if you provide the authorized agent written permission to do so or power of attorney. A verifiable consumer request may be submitted by you on behalf of your minor child.
        </div>
        <div class="title1">
            Contact for Information
        </div>
        <div class="content">
            If you have any questions or concerns about our privacy policies and practices, please do not hesitate to contact us:
        </div>
        <div class="content">
            <b>Email:</b> <a href="mailto:dataprivacy@funrize.com" target="_blank">admin@gamewonder.vip</a>
        </div>
        <div class="content">
            <b>Correspondence Address of Company:</b>
        </div>
        <div class="content">
            Attn: Privacy Policy Administrator
        </div>
        <div class="content">
            Game Wonder Limited,  ROOM 511,5/F,MING SANG IND BLDG,19-21 HING YIP STREET,KWUN  TONG,KLN,HONGKONG,KWUN TONG,KLN
        </div>
        <div class="title1">
            Changes to this Notice
        </div>
        <div class="content">
            This Notice may be updated from time to time to reflect changes in the legislation which is applicable and/or the way we work. We will notify you on the amendments by posting them here, or by other appropriate means.
        </div>
    </div>
</template>
<script>
export default {
    name:"CaliforniaPrivacyNoticeView",
    title: "CaliforniaPrivacyNoticeView",
    methods: {
        onClickHF: function(){
            this.$router.push("/")
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    font-size: 18px;
}
.title {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 23px
}

.title1 {
    display: block;
    text-align: left;
    font-weight: bold;
}

.version {
    display: block;
    text-align: left;
}

.content {
    display: block;
    text-align: left;
}

div {
    margin: 12px;
}

.privacy-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .privacy-table th,
  .privacy-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }
  
  .privacy-table th {
    background-color: white;
  }
</style>